
import { Component } from 'vue-property-decorator';
import AddressField from '@/shared/components/elements/address.vue';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import EmailForm from '@/modules/invoices/components/email-form.vue';
import { InvoiceLog } from '@/modules/invoices/shared/models/invoice-log';
import { invoiceLogNames } from '@/shared/config/invoices';
import { invoiceLogsModule } from '@/modules/invoices/shared/state/submodules';

@Component({
    props: {
        id: String,
        invoiceId: String,
    },
    components: {
        EmailForm,
        TopToolbar,
        AddressField,
    },
})
export default class InvoiceLogDetails extends DetailsComponent<InvoiceLog> {
    public invoiceId!: number;
    public actionsTypes = invoiceLogsModule.actionsTypes;
    public showAction = this.actionsTypes.SHOW_ITEM;
    public store = this.$store.state.invoicesState.logsState;

    public created() {
        //
    }

    get logName() {
        return this.item ? invoiceLogNames.find((obj) => obj.id === this.item.title) : null;
    }
}
