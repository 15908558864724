var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('items-table',{attrs:{"headers":_vm.headers,"props":_vm.props,"filter-config":_vm.filterConfig,"fetch-action":_vm.fetchAction,"mutation-types":_vm.mutationTypes,"item-name":"number","form-width":"750px","remove-action":_vm.removeAction,"disableDelete":true,"disableDeleteMessage":"Wystawionego dokumentu nie można usunąć"},scopedSlots:_vm._u([{key:"item.nr",fn:function({ item }){return [_c('a',{on:{"click":function($event){_vm.goToItem(
                        'invoice-details',
                        {
                            id: String(item.id),
                        },
                        item,
                    )}}},[_vm._v(" "+_vm._s(item ? item.number : ''))])]}},{key:"item.total_price",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.parsePrice(item.total_price, item.currency))+" ")]}},{key:"item.total_price_tax_excl",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.parsePrice(item.total_price_tax_excl, item.currency))+" ")]}},{key:"item.payment_method",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.paymentMethod(item.payment_method).name)+" ")]}},{key:"item.status",fn:function({ item }){return [_c('v-chip',{staticClass:"mr-sm-2",attrs:{"small":"","color":_vm.statusType(item.status).color}},[_vm._v(" "+_vm._s(_vm.statusType(item.status).name)+" ")])]}},{key:"item.paid",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","color":_vm.invoiceColor(item)}},[_vm._v(" "+_vm._s(item.paid ? 'Tak' : 'Nie')+" ")])]}},{key:"form",fn:function(){return [_c('invoice-form',{attrs:{"item-data":_vm.editedItem,"edit":_vm.edit,"show":_vm.formDialog,"approved":_vm.edit && _vm.editedItem.is_approved},on:{"saved":_vm.itemSaved,"canceled":function($event){return _vm.closeForm()},"next":function($event){_vm.formDialog = true}}})]},proxy:true},{key:"details",fn:function(){return [_c('invoice-details',{attrs:{"id":String(_vm.editedItem.id)},on:{"canceled":function($event){_vm.showDialog = false},"itemChanged":(id) => {
                        _vm.editedItem.id = id;
                    }}})]},proxy:true},{key:"filter-bar",fn:function(){return [_c('v-menu',{attrs:{"offset-y":"","activator":".export-btn"}},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.download('xlsx')}}},[_c('v-list-item-title',[_vm._v("Zapisz jako XLSX")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.download('csv')}}},[_c('v-list-item-title',[_vm._v("Zapisz jako CSV")])],1)],1)],1),(_vm.isPermitted('export'))?_c('tooltip-btn',{staticStyle:{"position":"relative"},attrs:{"contentClass":"export-btn","tooltipDisabled":_vm.isPermitted('export'),"tooltipText":"Brak uprawnień","icon":"","loading":_vm.downloading,"disabled":!_vm.isPermitted('export') || _vm.loading}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDownload))])],1):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }