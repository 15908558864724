import { render, staticRenderFns } from "./item-search.vue?vue&type=template&id=37f5ab4b&scoped=true&"
import script from "./item-search.vue?vue&type=script&lang=ts&"
export * from "./item-search.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f5ab4b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VCombobox,VRow})
