
import {Component} from 'vue-property-decorator';
import {mdiCheck, mdiDownload, mdiEmail, mdiFormatListBulleted, mdiPencil} from '@mdi/js';
import AddressField from '@/shared/components/elements/address.vue';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import {parsePrice} from '@/shared/helpers';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import InvoiceItemsList from '@/modules/invoices/components/items/list.vue';
import {Invoice} from '@/modules/invoices/shared/models/invoice';
import {FormRules} from '@/shared/validation/form-rules';
import EmailForm from '@/modules/invoices/components/email-form.vue';
import {invoicePaymentMethods, invoiceStatuses, invoiceTypes} from '@/shared/config/invoices';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import {SaveResponse} from '@/shared/types';
import {invoicesModule} from '@/modules/invoices/shared/state/module';
import InvoiceForm from '@/modules/invoices/components/form.vue';
import {InvoiceEmailRequest} from '@/modules/invoices/shared/requests/invoice-email-request';
import {invoiceItemsModule} from '@/modules/invoices/shared/state/submodules';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import NameReduce from '@/shared/components/elements/name-reduce.vue';

@Component({
    components: {
        EmailForm,
        TopToolbar,
        InvoiceForm,
        AddressField,
        ActionConfirmDialog,
        InvoiceItemsList,
        TooltipBtn,
        NameReduce,
    },
})
export default class InvoiceDetails extends DetailsComponent<Invoice> {
    public parsePrice = parsePrice;
    public icons: object = {
        mdiFormatListBulleted,
        mdiEmail,
        mdiDownload,
        mdiPencil,
        mdiCheck,
    };

    public form: any = null;
    public sendDialog: boolean = false;
    public confirmDialog: boolean = false;
    public formRules = new FormRules();
    public emailData: InvoiceEmailRequest = new InvoiceEmailRequest();
    public mutationTypes = invoicesModule.mutationsTypes;
    public actionsTypes = invoicesModule.actionsTypes;
    public itemsTypes = invoiceItemsModule.mutationsTypes;
    public store = this.$store.state.invoicesState;
    public showAction = this.actionsTypes.SHOW_ITEM;

    get approving() {
        return this.store.approvingItem;
    }

    get sending() {
        return this.store.sendingItem;
    }

    get downloading() {
        return this.store.downloadingItem;
    }

    get invoiceType() {
        return invoiceTypes.find((obj) => obj.id === this.item.type);
    }

    get paymentMethod() {
        return invoicePaymentMethods.find((obj) => obj.id === this.item.payment_method);
    }

    public created() {
        if (Object.keys(this.item).length < 1) {
            this.fetchDetails();
        } else {
            this.$store.commit(this.itemsTypes.DATA_SUCCESS, {
                data: this.item.items,
                meta: {
                    from: 1,
                    total: this.item.items.length,
                },
            });
        }
    }

    public mounted() {
        this.form = this.$refs.updateEmailForm;
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`invoices.${action}`);
    }

    public sendEmail(item: SaveResponse<Invoice>) {
        const buyer = item && item.data ? item.data.buyer : this.item.buyer;
        if (buyer && buyer.email) {
            this.fetchDetails();
            this.sendDialog = false;
            this.$store
                .dispatch(this.actionsTypes.SEND, this.id)
                .then(() => {
                    this.confirmDialog = false;
                })
                .catch(() => {
                    this.confirmDialog = false;
                });
        } else {
            this.sendDialog = true;
        }
    }

    public approveItem() {
        this.$store.dispatch(this.actionsTypes.APPROVE, this.id).then((response) => {
            if (response) {
                this.fetchDetails();
            }
        });
    }

    public editItem() {
        this.$store.commit(this.mutationTypes.UPDATE_EDITED, this.item);
        this.$store.commit(this.mutationTypes.UPDATE_FORM_DIALOG, true);
    }

    public closeForm() {
        this.$store.commit(this.mutationTypes.UPDATE_FORM_DIALOG, false);
    }

    public statusType(statusType: string) {
        return invoiceStatuses.find((obj) => obj.id === statusType);
    }

    public itemSaved() {
        this.store.formDialog = false;
        this.fetchDetails();
    }

    public download() {
        this.$store.dispatch(this.actionsTypes.DOWNLOAD, this.id).then((response) => {
            if (response && response instanceof ArrayBuffer) {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Faktura ' + this.item.number + '.pdf');
                document.body.appendChild(link);
                link.click();
            }
        });
    }

    public openEmailFormDialog() {
        this.emailData.id = this.item.id;
        this.emailData.recipient_email = this.item.buyer ? this.item.buyer.email : '';
        this.emailData.sender = this.item.seller ? this.item.seller.email : '';
        this.emailData.title = `Faktura numer ${this.item.number}`;
        this.emailData.content = `Dzień dobry,\nprzesyłam dokument ${this.item.number} na kwotę ${parsePrice(
            this.item.total_price,
            this.item.currency,
        )} brutto.`;
        this.sendDialog = true;
    }

    public updatePrices(taxExcl: number, taxTotal: number) {
        this.$set(this.item, 'total_price_tax_excl', taxExcl);
        this.$set(this.item, 'total_price', taxExcl + taxTotal);
    }
}
