
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import FormActions from '@/shared/components/layout/form/form-actions.vue';
    import ItemsSearch from '@/shared/components/layout/form/item-search.vue';
    import {SearchParams} from '@/modules/contractors/shared/models/search-params';
    import {logger} from '@/shared/services';
    import {contractorsModule} from '../shared/state/module';
    import AddressField from '@/shared/components/elements/address.vue';
    import {Contractor} from '@/modules/contractors/shared/models/contractor';
    import {gusTypes} from '@/shared/config/contractors';
    import {AddressData} from '@/modules/contractors/shared/payloads/gus-results-payload';

    @Component({
        components: {
            FormActions,
            ItemsSearch,
            AddressField,
        },
        props: {
            itemData: Object,
            edit: {
                type: Boolean,
                default: false,
            },
        },
    })
    export default class ContractorSearch extends Vue {
        public searchLocation: string = '';
        public search: string = ' ';
        public typingDelayTime: number = 600;
        public timer: any = 0;
        public value!: string;
        public searchResults: AddressData | null = null;
        public actionsTypes = contractorsModule.actionsTypes;
        public mutationsTypes = contractorsModule.mutationsTypes;
        public store: any = this.$store.state.contractorsState;

        @Watch('searchResults')
        public OnSearchSelected() {
            if (this.searchResults) {
                this.$emit('search-selected', this.searchResults);
            }
        }

        get loading() {
            return this.store.searching;
        }

        get items() {
            return this.store.searchingResults;
        }

        get types() {
            return gusTypes;
        }

        get error() {
            return this.store.searchError;
        }

        public selectedContractor() {
            this.$emit('selected', this.searchResults);
            this.searchLocation = '';
            this.clearSearch();
        }

        public closeWindow() {
            this.clearSearch();
            this.$emit('canceled');

        }

        public clearSearch() {
            this.$store.commit(this.mutationsTypes.CONTRACTORS_SEARCH_RESET);
            this.search = '';
            this.searchResults = null;
        }

        @Watch('search')
        public OnSearchChange(newVal: string) {
            if (newVal && (!this.searchResults || this.searchResults.name !== newVal)) {
                this.$emit('input', this.search);
                clearTimeout(this.timer);

                this.timer = setTimeout(() => {
                    this.searchItems();
                }, this.typingDelayTime);
            }
        }

        public searchItems() {
            const params = {
                location: this.searchLocation,
                search: this.search,
            } as SearchParams;

            this.$store.dispatch(this.actionsTypes.SEARCH_CONTRACTORS, params)
                .catch((err) => logger.error(err));
        }
    }
