
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {FetchParams, SearchItem} from '@/shared/types';
    import {actionsTypes} from '@/modules/settings/shared/state';
    import {logger} from '@/shared/services';
    import {objectTypes} from '@/shared/config/object-types';

    @Component({
        props: {
            errorMessages: Array,
            label: {
                type: String,
                default: 'Wyszukaj lub wpisz nazwę pozycji',
            },
            autofocus: Boolean,
            disabled: Boolean,
            loading: Boolean,
            searchLocation: String,
            value: String,
            rules: Array,
            searchPinned: Object,
        },
    })
    export default class ItemsSearch extends Vue {
        public store = this.$store.state.settingsState;
        public search: string = ' ';
        public searchLocation!: string;
        public typingDelayTime: number = 600;
        public timer: any = 0;
        public value!: string;
        public searchPinned!: SearchItem;
        public searchResults: SearchItem | null = null;

        @Watch('searchResults')
        public OnSearchSelected() {
            if (this.searchResults) {
                this.$emit('search-selected', this.searchResults);
            }
        }

        @Watch('value', {deep: true})
        public OnValueChange() {
            this.bindValue();
        }

        get error() {
            return this.$store.state.settingsState.error;
        }

        get errors() {
            return this.$store.state.settingsState.errorMessages;
        }

        get searching() {
            return this.store.searching;
        }

        get items() {
            return this.store.searchingResults;
        }

        @Watch('search')
        public OnSearchChange(newVal: string) {
            if (!this.searchResults || this.searchResults.name !== newVal) {
                this.$emit('input', this.search);
                this.$emit('change', this.search);
                clearTimeout(this.timer);
                if (newVal.length >= 3) {
                    this.timer = setTimeout(() => {
                        this.searchItems();
                    }, this.typingDelayTime);
                }
            }
        }

        public searchItems() {
            const params = {
                location: this.searchLocation,
                filters: {
                    search: this.search,
                },
            } as FetchParams;

            this.$store.dispatch(actionsTypes.SEARCH_ITEMS, params)
                .catch((err) => logger.error(err));
        }

        public bindValue() {
            this.search = this.value;
            this.searchResults = this.searchPinned;
        }

        public objectType(objectType: string) {
            const type = objectTypes.find((obj) => obj.id === objectType);
            return type ? type.name : '';
        }

        public created() {
            this.bindValue();
            if (this.searchPinned) {
                this.searchResults = this.searchPinned;
            }
        }
    }
