export class InvoiceEmailRequest {
    public id: number = 0;
    public sender?: string = '';
    public cc?: string;
    // tslint:disable-next-line:variable-name
    public recipient_email?: string = '';
    public title: string = '';
    public content: string = '';
    // tslint:disable-next-line:variable-name
    public attach_invoice: boolean = true;
}
