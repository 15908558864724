export class InvoiceDataRequest {
    public id: number = 0;
    // tslint:disable-next-line:variable-name
    public type: string = '';
    public status: string = '';
    // tslint:disable-next-line:variable-name
    public author_id: number = 0;
    // tslint:disable-next-line:variable-name
    public seller_id: number = 0;
    // tslint:disable-next-line:variable-name
    public department_address_id: number = 0;
    // tslint:disable-next-line:variable-name
    public original_invoice_id: number = 0;
    // tslint:disable-next-line:variable-name
    public recipient_id: number = 0;
    // tslint:disable-next-line:variable-name
    public recipient_type_name: string = '';
    // tslint:disable-next-line:variable-name
    public recipient_address_id: number = 0;
    // tslint:disable-next-line:variable-name
    public recipient_private: number = 0;
    // tslint:disable-next-line:variable-name
    public recipient_name?: string = '';
    // tslint:disable-next-line:variable-name
    public issue_place: string = '';
    // tslint:disable-next-line:variable-name
    public payment_method: string = '';
    public paid: number = 0;
    public number: string = '';
    // tslint:disable-next-line:variable-name
    public number_id: number = 0;
    public description: string = '';
    public currency: string = '';
    public note: string = '';
    public printed: number = 0;
    // tslint:disable-next-line:variable-name
    public issue_date: string = '';
    // tslint:disable-next-line:variable-name
    public sale_date: string = '';
    // tslint:disable-next-line:variable-name
    public payment_deadline: number = 0;
    // tslint:disable-next-line:variable-name
    public payed_at: string = '';
    // tslint:disable-next-line:variable-name
    public received_at: string = '';
    // tslint:disable-next-line:variable-name
    public sent_at: string = '';
    // tslint:disable-next-line:variable-name
    public returned_at: string = '';
}
