
import {Component} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import {invoicesModule} from '@/modules/invoices/shared/state/module';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {InvoiceEmailRequest} from '@/modules/invoices/shared/requests/invoice-email-request';

@Component({
    components: {FormActions},
    props: {
        id: Number,
    },
})
export default class EmailForm extends FormComponent<InvoiceEmailRequest> {
    public id!: number;
    public actionsTypes = invoicesModule.actionsTypes;
    public store = this.$store.state.invoicesState;
    public storeAction = this.actionsTypes.SEND_EMAIL;
    public updateAction = '';
    public requestData = new InvoiceEmailRequest();

    public initForm() {
        this.form = this.$refs.updateEmailForm;
         }

    get loading() {
        return this.store.sendingItem;
    }

    public created() {
        //
    }

    get formTitle() {
        return 'Wysłanie faktury e-mailem';
    }
}
