
import { Component, Watch } from 'vue-property-decorator';
import { contractorsModule } from '@/modules/contractors/shared/state/module';
import { ContractorDataRequest } from '@/modules/contractors/shared/requests/contractor-data-request';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import ItemsSearch from '@/shared/components/layout/form/item-search.vue';
import { mdiAccountSearch } from '@mdi/js';
import ContractorSearch from '@/modules/contractors/components/search.vue';
import { logger } from '@/shared/services';
import { SearchParams } from '../shared/models/search-params';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';

import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { contractorsTypesModule } from '../shared/state/settings/contractor-types/module';
import ContractorTypeForm from './settings/contractor-types/form.vue';
import { actionsTypes } from '@/modules/admin/shared/state';
import { investmentsModule } from '@/modules/investments/shared/state/module';
import { callSuper, parseDate } from '@/shared/helpers';
import { reservationDurations } from '@/shared/config/investments';
import { contractorsSpecializationsModule } from '../shared/state/settings/contractor-specializations/module';
import ContractorSpecializationsForm from './settings/contractor-specializations/form.vue';

import { investmentSubjectOperationsModule } from '@/modules/investments/shared/state/submodules';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import SubjectCard from '@/modules/investments/components/subjects/subject-card.vue';
import CardPicker from '@/shared/components/layout/form/card-picker.vue';
import Hint from '@/shared/components/elements/hint.vue';
import { pick } from 'lodash';
import PhoneInputMultiple from '@/shared/components/elements/phone-input-multiple.vue';

@Component({
    components: {
        FormActions,
        ItemsSearch,
        ContractorSearch,
        AutoCompleteWithAddNew,
        ActionConfirmDialog,
        SubjectCard,
        CardPicker,
        DatePicker,
        PhoneInputMultiple,
        Hint,
    },
})
export default class ContractorForm extends FormComponent<ContractorDataRequest> {
    public store = this.$store.state.contractorsState;
    public actionsTypes = contractorsModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public icons: object = {
        mdiAccountSearch,
    };
    public searchDialog: boolean = false;
    public investmentsStore = this.$store.state.investmentsState;

    // customer dialog
    public searchByNipDialog: boolean = false;
    public searchedCompanyData: any = {};
    public searchByNipLoading: boolean = false;
    public shouldSearchByNip: boolean = false;

    public subjectsStore = this.$store.state.investmentsState.subjectOperationsState;
    public subjectActions = investmentSubjectOperationsModule.actionsTypes;

    // data required for add new type
    public contractorsState = this.$store.state.contractorsState.contractorsTypesModuleState;
    public contractorsTypesModule = contractorsTypesModule;

    // data required for add new specialization
    public contractorsSpecializationState = this.$store.state.contractorsState.contractorsSpecializationsModuleState;
    public contractorsSpecializationsModule = contractorsSpecializationsModule;

    public ContractorTypeForm = ContractorTypeForm;
    public ContractorSpecializationsForm = ContractorSpecializationsForm;

    // guardian
    public users: any = [];
    public guardianId: any = null;
    public guardianLoading: boolean = false;
    public investmentsActionTypes = investmentsModule.actionsTypes;

    // add contractor reservation
    public reservationDurations = reservationDurations;
    public reservationDuration = 0;
    public showReservation = false;

    get investments() {
        return this.investmentsStore.dataList;
    }

    public created() {
        this.fetchInvestments();
        this.guardianLoading = true;
        this.$store
            .dispatch(actionsTypes.FETCH_USER_DATA)
            .then((res) => {
                this.users = this.pullToArraysHeadCurrentUser(res.data);
            })
            .finally(() => {
                this.guardianLoading = false;
            });
        this.$store.dispatch(this.investmentsActionTypes.FETCH_DATA, { simple: true });
    }

    get currentUser() {
        return this.$store.state.authState.user;
    }

    public fetchInvestments() {
        this.$store.dispatch(this.investmentsActionTypes.FETCH_DATA, { simple: true });
    }

    public pullToArraysHeadCurrentUser(arrayUsers: [any]) {
        const currentArray = [...arrayUsers];
        const { id, name } = this.currentUser;
        const currentUser = {
            ...this.currentUser,
            name: `(Ja) ${name}`,
        };
        const arrayWithoutCurrentUser = this._.filter(currentArray, this._.negate(this._.matches({ id })));
        return [currentUser, ...arrayWithoutCurrentUser];
    }

    public itemSave(item: ContractorDataRequest) {
        this.itemData = item;
        this.itemData.is_company = false;
        this.searchDialog = false;
    }

    get formTitle() {
        return this.edit ? 'Edytuj kontrahenta' : 'Dodaj kontrahenta';
    }

    public async submitForm(e: any, next?: boolean): Promise<void> {
        this.$nextTick(() => {

        if (!this.itemData.is_company) {
            delete this.itemData.is_company;
        }

        if (!this.showReservation) {
            delete this.itemData.related_investment_subject_id;
            delete this.itemData.reservation_end_date;
            delete this.itemData.reservation_start_date;
        }

        return callSuper(this, 'submitForm', e, next);
        });
    }

    public initForm() {
        this.form = this.$refs.createContractorForm;
    }

    public onReservationDurationChange() {
        if (this.reservationDuration === null) {
            return;
        }

        const reservationEndDate = new Date(
            this.itemData.reservation_start_date ? this.itemData.reservation_start_date : '',
        );
        reservationEndDate.setDate(reservationEndDate.getDate() + this.reservationDuration);

        this.$set(this.itemData, 'reservation_end_date', this.parseDate(reservationEndDate));
    }
    public onReservationStartDateChange(value: string) {
        if (this.reservationDuration === null) {
            return;
        }

        if (this.reservationDuration === 0) {
            this.itemData.reservation_start_date = this.parseDate(value);
        } else if (this.edit) {
            this.itemData.reservation_start_date = this.parseDate(value);
            if (this.reservationDuration !== 0) {
                const reservationEndDate = new Date(value);
                reservationEndDate.setDate(reservationEndDate.getDate() + this.reservationDuration);
                this.itemData.reservation_end_date = this.parseDate(reservationEndDate);
            }
        } else if (!this.edit) {
            const reservationEndDate = new Date(value);
            reservationEndDate.setDate(reservationEndDate.getDate() + this.reservationDuration);

            this.itemData.reservation_start_date = this.parseDate(value);

            this.itemData.reservation_end_date = this.parseDate(reservationEndDate);
        }
        this.$forceUpdate();
    }
    public onReservationEndDateChange(value: string) {
        this.itemData.reservation_end_date = this.parseDate(value);
    }

    public isCompanyChanged() {
        if (!this.itemData.is_company) {
            this.$delete(this.itemData, 'nip');
            this.$delete(this.itemData, 'short_name');
        }
    }

    @Watch('itemData.nip')
    public have10lenght() {
        if (
            this.shouldSearchByNip &&
            this.itemData.nip &&
            this.itemData.nip.length === 10 &&
            !this.searchByNipLoading
        ) {
            this.searchByNip();
        }
    }

    public searchByNip() {
        if (this.itemData.nip) {
            this.searchByNipLoading = true;

            const params = {
                location: 'nip',
                search: this.itemData.nip,
            } as SearchParams;

            this.$store
                .dispatch(this.actionsTypes.SEARCH_CONTRACTORS, params)
                .then((data) => {
                    if (data) {
                        const newData = data.data;
                        this.searchByNipDialog = true;
                        this.searchedCompanyData = pick(newData, Object.keys(this.requestData));

                        this.searchByNipLoading = false;
                    }
                })
                .catch((err) => {
                    this.searchByNipLoading = false;
                    logger.error(err);
                });
        }
    }

    public setitemDataAtSearchedCompanyData() {
        const isStillFitInBoundary = (currentShorWord: string, nextWord: string, maxWidth: number) =>
            currentShorWord.length + nextWord.length < maxWidth;

        const getShortName = (fullName: string, maxWidth: number) => {
            let shortName = '';
            const line = fullName.split(' ');

            for (const nextWord of line) {
                if (line.indexOf(nextWord) > 0) {
                    if (isStillFitInBoundary(shortName, nextWord, maxWidth)) {
                        shortName = `${shortName} ${nextWord}`;
                    } else {
                        break;
                    }
                } else {
                    shortName = nextWord;
                }
            }

            return shortName;
        };

        const data = Object.assign({}, this.searchedCompanyData);

        const newData = {
            ...data,
            name: data.name,
            short_name: getShortName(data.name, 20),
            phones: data.phones ? [data.phones] : undefined,
            emails: data.emails ? [data.emails] : undefined,
        };

        Object.keys(newData).forEach((key: string) => {
            if (newData[key]) {
                this.itemData[key] = newData[key];
            }
        });
    }

    // to delete email or phone
    public deleteItemFromArray(item: string, propertyName: string) {
        if (this.itemData[propertyName] && this.itemData[propertyName].length > 0) {
            this.itemData[propertyName] = this.itemData[propertyName].filter((e: any) => !this._.isEqual(item, e));
        }
    }

    @Watch('itemData.related_investments')
    public changeToString() {
        this.itemData.investments = this.itemData.related_investments;
    }
}
