
import Vue from 'vue';
import { phoneNumberPrefixes } from '@/shared/config/phone-numbers';
import { parsePhoneNumber } from 'awesome-phonenumber';
import Component from 'vue-class-component';
import { Prop, PropSync, VModel, Watch } from 'vue-property-decorator';
import { FormRules } from '@/shared/validation/form-rules';

@Component({})
export default class PhoneInput extends Vue {
    @VModel() public phoneNumber!: string;
    @Prop() public value!: string;
    @Prop({ type: String, default: 'Numer telefonu' }) public label!: string;
    @Prop({type: [Object, Array], default: () => []}) public rules!: FormRules;
    @Prop({type: Boolean, default: false}) public dense!: boolean;
    @Prop({type: Boolean, default: false}) public autofocus!: boolean;
    @PropSync('errorMessages', {type: Array, default: () => []}) public errorMsgSync!: string[];

    public prefixes = phoneNumberPrefixes;
    public prefix = {
        name: 'Poland',
        dial_code: '+48',
        emoji: '🇵🇱',
        code: 'PL',
        polish_name: 'Polska',
    };

    public showMenu = false;
    public search = '';
    public plainNumber = '';

    public phoneNumberValidation: boolean | string = false;

    public getResultHTML(resultCode: string, resultName: string) {
        let html = resultCode + ' ' + resultName;
        const highlightStart = `<span class="primary--text">`;
        const highlightEnd = `</span>`;

        if (this.search.startsWith('+')) {
            html =
                highlightStart +
                resultCode.slice(0, this.search.length) +
                highlightEnd +
                resultCode.slice(this.search.length, resultCode.length) +
                ' ' +
                resultName;
        } else if (this.search.trim() !== '') {
            html =
                resultCode +
                ' ' +
                highlightStart +
                resultName.slice(0, this.search.length) +
                highlightEnd +
                resultName.slice(this.search.length, resultName.length);
        }

        return html;
    }

    public detectPrefix() {
        if (!this.value) {
            return;
        }

        const parsedNumber = parsePhoneNumber(this.value, { regionCode: 'PL' });
        const newPrefix = phoneNumberPrefixes.find((el) => el.code === parsedNumber.regionCode);

        if (newPrefix) {
            this.prefix = newPrefix;
            return;
        }

        this.phoneNumberValidation = 'Niepoprawny numer';
    }

    public onKeydown(event: KeyboardEvent) {
        if (!this.showMenu && event.key !== 'Enter') {
            if (
                event.key.length === 1 &&
                isNaN(event.key as unknown as number) &&
                !['+', '-'].includes(event.key)
            ) {
                event.preventDefault();
            }
            return;
        }

        event.preventDefault();

        if (event.key.length === 1 && event.key !== ' ') {
            if (isNaN(event.key as unknown as number) && !isNaN(this.search as unknown as number) && this.search) {
                event.preventDefault();
                return;
            }
            this.search += event.key;
        } else if (event.key === 'Backspace') {
            this.search = this.search.slice(0, -1);
        }
    }

    public setDialCode(item: any) {
        if (this.phoneNumber.trim() === this.prefix.dial_code.trim()) {
            this.phoneNumber = ' ';
        }

        this.$nextTick(() => {
            this.prefix = item;
            if (this.phoneNumber === this.prefix.dial_code) {
                return;
            }

            const parsedNumber = parsePhoneNumber(this.phoneNumber);
            const national = parsedNumber.number?.national ?? '';
            const result = parsePhoneNumber(item.dial_code + national, { regionCode: item.code }).number;

            this.phoneNumber =
                result && result.international ? result.international : item.dial_code + ' ' + national;
        });
    }

    @Watch('search')
    public onSearch(val: string) {
        if (val === '') {
            this.prefixes = phoneNumberPrefixes;
        } else {
            if (!this.search.startsWith('+') && isNaN(this.search as unknown as number)) {
                this.prefixes = phoneNumberPrefixes.filter((el) =>
                    el.polish_name.toLowerCase().startsWith(val.toLowerCase().trim()),
                );
                return;
            }
            if (!this.search.startsWith('+')) { this.search = '+' + this.search; }
            this.prefixes = phoneNumberPrefixes.filter((el) =>
                (el.dial_code + el.polish_name.toLowerCase()).startsWith(val.toLowerCase().trim()),
            );
        }
    }

    @Watch('showMenu')
    public onShowMenuChange(val: boolean) {
        if (!val) {
            this.search = '';
        }
    }

    @Watch('phoneNumber', { immediate: true })
    public onPlainNumberChange(val: string) {
        this.phoneNumberValidation = true;
        if (!this.value) {
            return;
        }
        const parsedNumber = parsePhoneNumber(val, { regionCode: this.prefix.code });

        if (parsedNumber.regionCode !== this.prefix.code) {
            this.detectPrefix();
        }

        if (parsedNumber.possible) {
            this.phoneNumber = parsedNumber.number!.international;
        } else {
            this.phoneNumberValidation = 'Niepoprawny numer';
        }
    }

    @Watch('value', { immediate: true })
    public onValueChange(val: string) {
        if (val === this.phoneNumber) {
            return;
        }
        this.phoneNumber = val.replace(this.prefix.dial_code, '').trim();
    }
}
