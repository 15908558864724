var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"ma-0 pa-0 pb-5 mt-2"},[_c('v-form',{ref:"invoiceItemsForm",attrs:{"id":"invoice-items-form"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.items,"disable-sort":"","title":"Pozycje kosztów","items-per-page":-1,"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('list-toolbar',{attrs:{"add-button":true,"add-button-title":"dodaj","add-button-tooltip":_vm.isPermitted('create') ? 'Dodaj pozycje' : 'Brak uprawnień',"disabled":!_vm.editable ||
                            _vm.loadingItem ||
                            !_vm.newItemSubmited ||
                            Object.keys(_vm.formErrorMessages).length > 0 ||
                            !_vm.valid ||
                            !_vm.isPermitted('create')},on:{"create-item":_vm.addItem}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-expand-x-transition',[(_vm.loadingItem)?_c('v-progress-circular',{key:"loader",staticClass:"mr-4",attrs:{"indeterminate":"","size":"32","color":"primary"}}):_vm._e()],1),_c('div',{key:"title"},[_vm._v("Pozycje faktury")])],1)],1)]},proxy:true},{key:"body",fn:function(props){return [(_vm.items && _vm.items.length > 0 && !_vm.loading)?_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:item.id},[_c('td',[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticStyle:{"min-width":"100px"}},[(!_vm.editable)?_c('span',[_vm._v(_vm._s(item.name ? item.name : ''))]):_c('v-text-field',{class:'item-' + item.id,attrs:{"label":"","rules":[_vm.formRules.required],"autofocus":item.index === _vm.items.length,"disabled":Object.keys(_vm.formErrorMessages).length > 0 &&
                                        _vm.formErrorMessages.id !== item.id,"error-messages":_vm.formErrorMessages.id === item.id
                                            ? _vm.formErrorMessages.name
                                            : _vm.itemsErrors[`item_${item.index - 1}`]
                                            ? _vm.itemsErrors[`item_${item.index - 1}`].name
                                            : []},on:{"blur":function($event){return _vm.selectChanged(item)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updateItem(item)},"change":function($event){return _vm.clearError('name', item.index - 1)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c('td',{staticStyle:{"min-width":"100px"}},[(!_vm.editable)?_c('span',[_vm._v(" "+_vm._s(item.quantity ? item.quantity : '')+" ")]):_c('v-text-field',{class:'item-' + item.id,staticStyle:{"max-width":"100px"},attrs:{"type":"number","disabled":Object.keys(_vm.formErrorMessages).length > 0 &&
                                        _vm.formErrorMessages.id !== item.id,"error-messages":_vm.formErrorMessages.id === item.id
                                            ? _vm.formErrorMessages.quantity
                                            : _vm.itemsErrors[`item_${item.index - 1}`]
                                            ? _vm.itemsErrors[`item_${item.index - 1}`].quantity
                                            : [],"min":"0","rules":[_vm.formRules.required, _vm.formRules.notLesserThanAndEqualZero]},on:{"change":function($event){return _vm.clearError('quantity', item.index - 1)},"blur":function($event){return _vm.selectChanged(item)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updateItem(item)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1),_c('td',{staticStyle:{"min-width":"100px"}},[(!_vm.editable)?_c('span',[_vm._v(" "+_vm._s(item.unit ? item.unit : '')+" ")]):_c('v-select',{ref:`item-${item.id}`,refInFor:true,class:`item-${item.id}`,staticStyle:{"max-width":"100px"},attrs:{"loading":_vm.loadingUnits,"rules":_vm.formRules.requiredSelect,"items":_vm.units,"item-text":"name","item-value":"name","return-object":false,"disabled":Object.keys(_vm.formErrorMessages).length > 0 &&
                                        _vm.formErrorMessages.id !== item.id,"error-messages":_vm.formErrorMessages.id === item.id
                                            ? _vm.formErrorMessages.unit
                                            : _vm.itemsErrors && _vm.itemsErrors[`item_${item.index - 1}`]
                                            ? _vm.itemsErrors[`item_${item.index - 1}`].unit
                                            : []},on:{"change":function($event){return _vm.clearError('unit', item.index - 1)},"blur":function($event){return _vm.selectChanged(item)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updateItem(item)}},model:{value:(item.unit),callback:function ($$v) {_vm.$set(item, "unit", $$v)},expression:"item.unit"}})],1),_c('td',{staticStyle:{"min-width":"100px"}},[(!_vm.editable)?_c('span',[_vm._v(" "+_vm._s(item.tax_rate ? item.tax_rate : '0')+_vm._s(!isNaN(item.tax_rate) ? '%' : '')+" ")]):_c('v-select',{class:`item-${item.id}`,staticStyle:{"max-width":"100px"},attrs:{"loading":_vm.loadingTaxRates,"items":_vm.taxRates,"item-text":"rate","item-value":"rate","return-object":false,"rules":_vm.formRules.requiredSelect,"disabled":Object.keys(_vm.formErrorMessages).length > 0 &&
                                        _vm.formErrorMessages.id !== item.id,"error-messages":_vm.formErrorMessages.id === item.id ? _vm.formErrorMessages.tax_rate : [],"suffix":item.tax_rate && Number(item.tax_rate) ? '%' : null},on:{"change":function($event){return _vm.clearError('tax_rate', item.index - 1)},"blur":function($event){return _vm.selectChanged(item)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updateItem(item)}},model:{value:(item.tax_rate),callback:function ($$v) {_vm.$set(item, "tax_rate", $$v)},expression:"item.tax_rate"}})],1),_c('td',{staticStyle:{"min-width":"100px"}},[(!_vm.editable)?_c('span',[_vm._v(" "+_vm._s(_vm.parsePrice(item.price, null))+" ")]):_c('v-text-field',{class:'item-' + item.id,staticStyle:{"width":"150px"},attrs:{"type":"number","rules":[_vm.formRules.required, _vm.formRules.notLesserThanZero],"disabled":Object.keys(_vm.formErrorMessages).length > 0 &&
                                        _vm.formErrorMessages.id !== item.id,"min":"0.00","step":"0.01","error-messages":_vm.formErrorMessages.id === item.id
                                            ? _vm.formErrorMessages.price
                                            : _vm.itemsErrors && _vm.itemsErrors[`item_${item.index - 1}`]
                                            ? _vm.itemsErrors[`item_${item.index - 1}`].price
                                            : [],"suffix":_vm.currency},on:{"change":function($event){return _vm.clearError('price', item.index - 1)},"keyup":function($event){return _vm.$forceUpdate()},"blur":function($event){return _vm.selectChanged(item)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updateItem(item)}},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})],1),_c('td',{staticStyle:{"min-width":"100px"}},[(!_vm.editable)?_c('span',[_vm._v(" "+_vm._s(_vm.parsePrice(item.total_price_tax_excl, _vm.currency))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.parsePrice(_vm.priceTaxExcl(_vm.items[item.index - 1]), null))+" ")])]),_c('td',{staticStyle:{"min-width":"100px"}},[(!_vm.editable)?_c('span',[_vm._v(" "+_vm._s(_vm.parsePrice(item.tax_total, null))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.parsePrice(_vm.taxTotal(_vm.items[item.index - 1]), null))+" ")])]),_c('td',{staticStyle:{"min-width":"100px"}},[(!_vm.editable)?_c('span',[_vm._v(" "+_vm._s(_vm.parsePrice(item.total_price_tax_incl, null))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.parsePrice( _vm.priceTaxExcl(_vm.items[item.index - 1]) + _vm.taxTotal(_vm.items[item.index - 1]), null, ))+" ")])]),(_vm.editable)?_c('td',{staticClass:"text-center",staticStyle:{"min-width":"100px"}},[_c('tooltip-btn',{attrs:{"tooltipDisabled":_vm.isPermitted('delete'),"tooltipText":"Brak uprawnień","icon":"","disabled":(Object.keys(_vm.formErrorMessages).length > 0 &&
                                            _vm.formErrorMessages.id !== item.id) ||
                                        !_vm.isPermitted('delete')},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{staticClass:"pa-y",attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1):_vm._e()])}),0):((!_vm.items || _vm.items.length === 0) && !_vm.loading)?_c('tbody',{attrs:{"width":"100%"}},[_c('tr',{staticStyle:{"pointer-events":"none"}},[_c('td',{staticClass:"text-center",attrs:{"colspan":"19"}},[_vm._v("Brak pozycji faktury")])])]):_c('tbody',{attrs:{"width":"100%"}},[_c('tr',{staticStyle:{"pointer-events":"none"}})])]}}]),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}})],1)],1),_c('action-confirm-dialog',{attrs:{"active":_vm.removeDialog,"loading":_vm.removing,"title":`Czy na pewno usunąć ${_vm.props.names.accusative || _vm.props.names.one}?`,"name":_vm.current ? _vm.current.name : '',"error":_vm.error,"confirm-title":"Usuń"},on:{"canceled":function($event){return _vm.closeRemove()},"confirmed":function($event){return _vm.removeItemRequest()}}},[_vm._t("remove-dialog")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }