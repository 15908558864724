import {ListFilter} from '@/shared/types';

export const invoicesListFilters: ListFilter[] = [
    {
        name: 'total_price_tax_excl',
        type: 'range',
        label: 'Koszt',
        description: 'Wybierz zakres kosztu faktury (bez VAT)',
    },
    {
        name: 'total_price_tax_incl',
        type: 'range',
        label: 'Koszt z VAT',
        description: 'Wybierz zakres kosztu faktury (z VAT)',
    },
    {name: 'payment_deadline', type: 'date', label: 'Termin płatności', description: 'Wybierz zakres dat (od - do)'},
    {
        name: 'payment_method', label: 'Metoda płatności', type: 'select', data: [
            {name: 'cash', label: 'Gotówka', value: false},
            {name: 'transfer', label: 'Przelew', value: false},
        ],
    },
    {name: 'payed_at', type: 'date', label: 'Data płatności', description: 'Wybierz zakres dat (od - do)'},
    {
        name: 'status', label: 'Status', type: 'select', data: [
            {name: 'new', label: 'Nowa', value: false},
            {name: 'approved', label: 'Zatwierdzona', value: false},
            {name: 'sent', label: 'Wysłana', value: false},
            {name: 'rejected', label: 'Odrzucona', value: false},
        ],
    },
    {
        name: 'paid', label: 'Opłacona', type: 'select', data: [
            {name: '1', label: 'Tak', value: false},
            {name: '0', label: 'Nie', value: false},
        ],
    },
];

export const invoiceItemsListFilters: ListFilter[] = [
    {name: 'quantity', type: 'range', label: 'Ilość', description: 'Podaj zakres ilości w pozycji'},
    {
        name: 'price_tax_excl',
        type: 'range',
        label: 'Cena netto',
        description: 'Podaj zakres ceny netto',
    },
    {
        name: 'tax_total',
        type: 'range',
        label: 'Kwota VAT',
        description: 'Podaj zakres kwoty VAT',
    },
    {
        name: 'total_price_tax_incl',
        type: 'range',
        label: 'Kwota brutto',
        description: 'Podaj zakres ceny brutto',
    },
];
