
import { Component, Prop } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import { InvoiceLog } from '@/modules/invoices/shared/models/invoice-log';
import InvoiceLogDetails from '@/modules/invoices/components/logs/details.vue';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import { invoiceLogListHeaders } from '@/modules/invoices/shared/config';
import { invoiceLogNames } from '@/shared/config/invoices';
import { invoiceLogsModule } from '@/modules/invoices/shared/state/submodules';
import ItemsTable from '@/shared/components/layout/list.vue';
import { DataRequest } from '@/shared/types';
import { ModuleProps } from '@/shared/state/template/module-props';

@Component({
    components: {
        InvoiceLogDetails,
        TopToolbar,
        ItemsTable,
    },
})
export default class InvoiceLogsList extends ListComponent<InvoiceLog, DataRequest> {
    @Prop(String) public invoiceId!: string;
    public mutationTypes = invoiceLogsModule.mutationsTypes;
    public actionsTypes = invoiceLogsModule.actionsTypes;
    public props: ModuleProps = invoiceLogsModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = '';
    public store = this.$store.state.invoicesState.logsState;
    public headers = invoiceLogListHeaders;

    public created() {
        this.id = Number(this.invoiceId);
    }

    public logName(logName: string) {
        return invoiceLogNames.find((obj) => obj.id === logName);
    }

    get logs() {
        return this.store.data;
    }
}
