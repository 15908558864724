
export const phoneNumberPrefixes:
    Array<{ name: string, dial_code: string, emoji: string, code: string, polish_name: string }> = [
        {
            name: 'Afghanistan',
            dial_code: '+93',
            emoji: '🇦🇫',
            code: 'AF',
            polish_name: 'Afganistan'
        },
        {
            name: 'Aland Islands',
            dial_code: '+358',
            emoji: '🇦🇽',
            code: 'AX',
            polish_name: 'Wyspy Alandzkie'
        },
        {
            name: 'Albania',
            dial_code: '+355',
            emoji: '🇦🇱',
            code: 'AL',
            polish_name: 'Albania'
        },
        {
            name: 'Algeria',
            dial_code: '+213',
            emoji: '🇩🇿',
            code: 'DZ',
            polish_name: 'Algieria'
        },
        {
            name: 'AmericanSamoa',
            dial_code: '+1 684',
            emoji: '🇦🇸',
            code: 'AS',
            polish_name: 'Samoa Amerykańskie'
        },
        {
            name: 'Andorra',
            dial_code: '+376',
            emoji: '🇦🇩',
            code: 'AD',
            polish_name: 'Andora'
        },
        {
            name: 'Angola',
            dial_code: '+244',
            emoji: '🇦🇴',
            code: 'AO',
            polish_name: 'Angola'
        },
        {
            name: 'Anguilla',
            dial_code: '+1 264',
            emoji: '🇦🇮',
            code: 'AI',
            polish_name: 'Anguilla'
        },
        {
            name: 'Antarctica',
            dial_code: '+672',
            emoji: '🇦🇶',
            code: 'AQ',
            polish_name: 'Antarktyka'
        },
        {
            name: 'Antigua and Barbuda',
            dial_code: '+1 268',
            emoji: '🇦🇬',
            code: 'AG',
            polish_name: 'Antigua i Barbuda'
        },
        {
            name: 'Argentina',
            dial_code: '+54',
            emoji: '🇦🇷',
            code: 'AR',
            polish_name: 'Argentyna'
        },
        {
            name: 'Armenia',
            dial_code: '+374',
            emoji: '🇦🇲',
            code: 'AM',
            polish_name: 'Armenia'
        },
        {
            name: 'Aruba',
            dial_code: '+297',
            emoji: '🇦🇼',
            code: 'AW',
            polish_name: 'Aruba'
        },
        {
            name: 'Australia',
            dial_code: '+61',
            emoji: '🇦🇺',
            code: 'AU',
            polish_name: 'Australia'
        },
        {
            name: 'Austria',
            dial_code: '+43',
            emoji: '🇦🇹',
            code: 'AT',
            polish_name: 'Austria'
        },
        {
            name: 'Azerbaijan',
            dial_code: '+994',
            emoji: '🇦🇿',
            code: 'AZ',
            polish_name: 'Azerbejdżan'
        },
        {
            name: 'Bahamas',
            dial_code: '+1 242',
            emoji: '🇧🇸',
            code: 'BS',
            polish_name: 'Bahamy'
        },
        {
            name: 'Bahrain',
            dial_code: '+973',
            emoji: '🇧🇭',
            code: 'BH',
            polish_name: 'Bahrajn'
        },
        {
            name: 'Bangladesh',
            dial_code: '+880',
            emoji: '🇧🇩',
            code: 'BD',
            polish_name: 'Bangladesz'
        },
        {
            name: 'Barbados',
            dial_code: '+1 246',
            emoji: '🇧🇧',
            code: 'BB',
            polish_name: 'Barbados'
        },
        {
            name: 'Belarus',
            dial_code: '+375',
            emoji: '🇧🇾',
            code: 'BY',
            polish_name: 'Białoruś'
        },
        {
            name: 'Belgium',
            dial_code: '+32',
            emoji: '🇧🇪',
            code: 'BE',
            polish_name: 'Belgia'
        },
        {
            name: 'Belize',
            dial_code: '+501',
            emoji: '🇧🇿',
            code: 'BZ',
            polish_name: 'Belize'
        },
        {
            name: 'Benin',
            dial_code: '+229',
            emoji: '🇧🇯',
            code: 'BJ',
            polish_name: 'Benin'
        },
        {
            name: 'Bermuda',
            dial_code: '+1 441',
            emoji: '🇧🇲',
            code: 'BM',
            polish_name: 'Bermudy'
        },
        {
            name: 'Bhutan',
            dial_code: '+975',
            emoji: '🇧🇹',
            code: 'BT',
            polish_name: 'Bhutan'
        },
        {
            name: 'Bolivia, Plurinational State of',
            dial_code: '+591',
            emoji: '🇧🇴',
            code: 'BO',
            polish_name: 'Boliwia'
        },
        {
            name: 'Bosnia and Herzegovina',
            dial_code: '+387',
            emoji: '🇧🇦',
            code: 'BA',
            polish_name: 'Bośnia i Hercegowina'
        },
        {
            name: 'Botswana',
            dial_code: '+267',
            emoji: '🇧🇼',
            code: 'BW',
            polish_name: 'Botswana'
        },
        {
            name: 'Brazil',
            dial_code: '+55',
            emoji: '🇧🇷',
            code: 'BR',
            polish_name: 'Brazylia'
        },
        {
            name: 'British Indian Ocean Territory',
            dial_code: '+246',
            emoji: '🇮🇴',
            code: 'IO',
            polish_name: 'Brytyjskie Terytorium Oceanu Indyjskiego'
        },
        {
            name: 'Brunei Darussalam',
            dial_code: '+673',
            emoji: '🇧🇳',
            code: 'BN',
            polish_name: 'Brunei'
        },
        {
            name: 'Bulgaria',
            dial_code: '+359',
            emoji: '🇧🇬',
            code: 'BG',
            polish_name: 'Bułgaria'
        },
        {
            name: 'Burkina Faso',
            dial_code: '+226',
            emoji: '🇧🇫',
            code: 'BF',
            polish_name: 'Burkina Faso'
        },
        {
            name: 'Burundi',
            dial_code: '+257',
            emoji: '🇧🇮',
            code: 'BI',
            polish_name: 'Burundi'
        },
        {
            name: 'Cambodia',
            dial_code: '+855',
            emoji: '🇰🇭',
            code: 'KH',
            polish_name: 'Kambodża'
        },
        {
            name: 'Cameroon',
            dial_code: '+237',
            emoji: '🇨🇲',
            code: 'CM',
            polish_name: 'Kamerun'
        },
        {
            name: 'Canada',
            dial_code: '+1',
            emoji: '🇨🇦',
            code: 'CA',
            polish_name: 'Kanada'
        },
        {
            name: 'Cape Verde',
            dial_code: '+238',
            emoji: '🇨🇻',
            code: 'CV',
            polish_name: 'Republika Zielonego Przylądka'
        },
        {
            name: 'Cayman Islands',
            dial_code: '+345',
            emoji: '🇰🇾',
            code: 'KY',
            polish_name: 'Kajmany'
        },
        {
            name: 'Central African Republic',
            dial_code: '+236',
            emoji: '🇨🇫',
            code: 'CF',
            polish_name: 'Republika Środkowoafrykańska'
        },
        {
            name: 'Chad',
            dial_code: '+235',
            emoji: '🇹🇩',
            code: 'TD',
            polish_name: 'Czad'
        },
        {
            name: 'Chile',
            dial_code: '+56',
            emoji: '🇨🇱',
            code: 'CL',
            polish_name: 'Chile'
        },
        {
            name: 'China',
            dial_code: '+86',
            emoji: '🇨🇳',
            code: 'CN',
            polish_name: 'Chiny'
        },
        {
            name: 'Christmas Island',
            dial_code: '+61',
            emoji: '🇨🇽',
            code: 'CX',
            polish_name: 'Wyspa Bożego Narodzenia'
        },
        {
            name: 'Cocos (Keeling) Islands',
            dial_code: '+61',
            emoji: '🇨🇨',
            code: 'CC',
            polish_name: 'Wyspy Kokosowe'
        },
        {
            name: 'Colombia',
            dial_code: '+57',
            emoji: '🇨🇴',
            code: 'CO',
            polish_name: 'Kolumbia'
        },
        {
            name: 'Comoros',
            dial_code: '+269',
            emoji: '🇰🇲',
            code: 'KM',
            polish_name: 'Komory'
        },
        {
            name: 'Congo',
            dial_code: '+242',
            emoji: '🇨🇬',
            code: 'CG',
            polish_name: 'Kongo'
        },
        {
            name: 'Congo, The Democratic Republic of the Congo',
            dial_code: '+243',
            emoji: '🇨🇩',
            code: 'CD',
            polish_name: 'Demokratyczna Republika Konga'
        },
        {
            name: 'Cook Islands',
            dial_code: '+682',
            emoji: '🇨🇰',
            code: 'CK',
            polish_name: 'Wyspy Cooka'
        },
        {
            name: 'Costa Rica',
            dial_code: '+506',
            emoji: '🇨🇷',
            code: 'CR',
            polish_name: 'Kostaryka'
        },
        {
            name: 'Cote d\'Ivoire',
            dial_code: '+225',
            emoji: '🇨🇮',
            code: 'CI',
            polish_name: 'Wybrzeże Kości Słoniowej'
        },
        {
            name: 'Croatia',
            dial_code: '+385',
            emoji: '🇭🇷',
            code: 'HR',
            polish_name: 'Chorwacja'
        },
        {
            name: 'Cuba',
            dial_code: '+53',
            emoji: '🇨🇺',
            code: 'CU',
            polish_name: 'Kuba'
        },
        {
            name: 'Cyprus',
            dial_code: '+357',
            emoji: '🇨🇾',
            code: 'CY',
            polish_name: 'Cypr'
        },
        {
            name: 'Czech Republic',
            dial_code: '+420',
            emoji: '🇨🇿',
            code: 'CZ',
            polish_name: 'Czechy'
        },
        {
            name: 'Denmark',
            dial_code: '+45',
            emoji: '🇩🇰',
            code: 'DK',
            polish_name: 'Dania'
        },
        {
            name: 'Djibouti',
            dial_code: '+253',
            emoji: '🇩🇯',
            code: 'DJ',
            polish_name: 'Dżibuti'
        },
        {
            name: 'Dominica',
            dial_code: '+1 767',
            emoji: '🇩🇲',
            code: 'DM',
            polish_name: 'Dominika'
        },
        {
            name: 'Dominican Republic',
            dial_code: '+1 849',
            emoji: '🇩🇴',
            code: 'DO',
            polish_name: 'Republika Dominikańska'
        },
        {
            name: 'Ecuador',
            dial_code: '+593',
            emoji: '🇪🇨',
            code: 'EC',
            polish_name: 'Ekwador'
        },
        {
            name: 'Egypt',
            dial_code: '+20',
            emoji: '🇪🇬',
            code: 'EG',
            polish_name: 'Egipt'
        },
        {
            name: 'El Salvador',
            dial_code: '+503',
            emoji: '🇸🇻',
            code: 'SV',
            polish_name: 'Salwador'
        },
        {
            name: 'Equatorial Guinea',
            dial_code: '+240',
            emoji: '🇬🇶',
            code: 'GQ',
            polish_name: 'Gwinea Równikowa'
        },
        {
            name: 'Eritrea',
            dial_code: '+291',
            emoji: '🇪🇷',
            code: 'ER',
            polish_name: 'Erytrea'
        },
        {
            name: 'Estonia',
            dial_code: '+372',
            emoji: '🇪🇪',
            code: 'EE',
            polish_name: 'Estonia'
        },
        {
            name: 'Ethiopia',
            dial_code: '+251',
            emoji: '🇪🇹',
            code: 'ET',
            polish_name: 'Etiopia'
        },
        {
            name: 'Falkland Islands (Malvinas)',
            dial_code: '+500',
            emoji: '🇫🇰',
            code: 'FK',
            polish_name: 'Falklandy'
        },
        {
            name: 'Faroe Islands',
            dial_code: '+298',
            emoji: '🇫🇴',
            code: 'FO',
            polish_name: 'Wyspy Owcze'
        },
        {
            name: 'Fiji',
            dial_code: '+679',
            emoji: '🇫🇯',
            code: 'FJ',
            polish_name: 'Fidżi'
        },
        {
            name: 'Finland',
            dial_code: '+358',
            emoji: '🇫🇮',
            code: 'FI',
            polish_name: 'Finlandia'
        },
        {
            name: 'France',
            dial_code: '+33',
            emoji: '🇫🇷',
            code: 'FR',
            polish_name: 'Francja'
        },
        {
            name: 'French Guiana',
            dial_code: '+594',
            emoji: '🇬🇫',
            code: 'GF',
            polish_name: 'Gujana Francuska'
        },
        {
            name: 'French Polynesia',
            dial_code: '+689',
            emoji: '🇵🇫',
            code: 'PF',
            polish_name: 'Polinezja Francuska'
        },
        {
            name: 'Gabon',
            dial_code: '+241',
            emoji: '🇬🇦',
            code: 'GA',
            polish_name: 'Gabon'
        },
        {
            name: 'Gambia',
            dial_code: '+220',
            emoji: '🇬🇲',
            code: 'GM',
            polish_name: 'Gambia'
        },
        {
            name: 'Georgia',
            dial_code: '+995',
            emoji: '🇬🇪',
            code: 'GE',
            polish_name: 'Gruzja'
        },
        {
            name: 'Germany',
            dial_code: '+49',
            emoji: '🇩🇪',
            code: 'DE',
            polish_name: 'Niemcy'
        },
        {
            name: 'Ghana',
            dial_code: '+233',
            emoji: '🇬🇭',
            code: 'GH',
            polish_name: 'Ghana'
        },
        {
            name: 'Gibraltar',
            dial_code: '+350',
            emoji: '🇬🇮',
            code: 'GI',
            polish_name: 'Gibraltar'
        },
        {
            name: 'Greece',
            dial_code: '+30',
            emoji: '🇬🇷',
            code: 'GR',
            polish_name: 'Grecja'
        },
        {
            name: 'Greenland',
            dial_code: '+299',
            emoji: '🇬🇱',
            code: 'GL',
            polish_name: 'Grenlandia'
        },
        {
            name: 'Grenada',
            dial_code: '+1 473',
            emoji: '🇬🇩',
            code: 'GD',
            polish_name: 'Grenada'
        },
        {
            name: 'Guadeloupe',
            dial_code: '+590',
            emoji: '🇬🇵',
            code: 'GP',
            polish_name: 'Gwadelupa'
        },
        {
            name: 'Guam',
            dial_code: '+1 671',
            emoji: '🇬🇺',
            code: 'GU',
            polish_name: 'Guam'
        },
        {
            name: 'Guatemala',
            dial_code: '+502',
            emoji: '🇬🇹',
            code: 'GT',
            polish_name: 'Gwatemala'
        },
        {
            name: 'Guernsey',
            dial_code: '+44',
            emoji: '🇬🇬',
            code: 'GG',
            polish_name: 'Guernsey'
        },
        {
            name: 'Guinea',
            dial_code: '+224',
            emoji: '🇬🇳',
            code: 'GN',
            polish_name: 'Gwinea'
        },
        {
            name: 'Guinea-Bissau',
            dial_code: '+245',
            emoji: '🇬🇼',
            code: 'GW',
            polish_name: 'Gwinea Bissau'
        },
        {
            name: 'Guyana',
            dial_code: '+595',
            emoji: '🇬🇾',
            code: 'GY',
            polish_name: 'Gujana'
        },
        {
            name: 'Haiti',
            dial_code: '+509',
            emoji: '🇭🇹',
            code: 'HT',
            polish_name: 'Haiti'
        },
        {
            name: 'Holy See (Vatican City State)',
            dial_code: '+379',
            emoji: '🇻🇦',
            code: 'VA',
            polish_name: 'Watykan'
        },
        {
            name: 'Honduras',
            dial_code: '+504',
            emoji: '🇭🇳',
            code: 'HN',
            polish_name: 'Honduras'
        },
        {
            name: 'Hong Kong',
            dial_code: '+852',
            emoji: '🇭🇰',
            code: 'HK',
            polish_name: 'Hongkong'
        },
        {
            name: 'Hungary',
            dial_code: '+36',
            emoji: '🇭🇺',
            code: 'HU',
            polish_name: 'Węgry'
        },
        {
            name: 'Iceland',
            dial_code: '+354',
            emoji: '🇮🇸',
            code: 'IS',
            polish_name: 'Islandia'
        },
        {
            name: 'India',
            dial_code: '+91',
            emoji: '🇮🇳',
            code: 'IN',
            polish_name: 'Indie'
        },
        {
            name: 'Indonesia',
            dial_code: '+62',
            emoji: '🇮🇩',
            code: 'ID',
            polish_name: 'Indonezja'
        },
        {
            name: 'Iran, Islamic Republic of Persian Gulf',
            dial_code: '+98',
            emoji: '🇮🇷',
            code: 'IR',
            polish_name: 'Iran'
        },
        {
            name: 'Iraq',
            dial_code: '+964',
            emoji: '🇮🇷',
            code: 'IQ',
            polish_name: 'Irak'
        },
        {
            name: 'Ireland',
            dial_code: '+353',
            emoji: '🇮🇪',
            code: 'IE',
            polish_name: 'Irlandia'
        },
        {
            name: 'Isle of Man',
            dial_code: '+44',
            emoji: '🇮🇲',
            code: 'IM',
            polish_name: 'Wyspa Man'
        },
        {
            name: 'Israel',
            dial_code: '+972',
            emoji: '🇮🇱',
            code: 'IL',
            polish_name: 'Izrael'
        },
        {
            name: 'Italy',
            dial_code: '+39',
            emoji: '🇮🇹',
            code: 'IT',
            polish_name: 'Włochy'
        },
        {
            name: 'Jamaica',
            dial_code: '+1 876',
            emoji: '🇯🇲',
            code: 'JM',
            polish_name: 'Jamajka'
        },
        {
            name: 'Japan',
            dial_code: '+81',
            emoji: '🇯🇵',
            code: 'JP',
            polish_name: 'Japonia'
        },
        {
            name: 'Jersey',
            dial_code: '+44',
            emoji: '🇯🇪',
            code: 'JE',
            polish_name: 'Jersey'
        },
        {
            name: 'Jordan',
            dial_code: '+962',
            emoji: '🇯🇴',
            code: 'JO',
            polish_name: 'Jordania'
        },
        {
            name: 'Kazakhstan',
            dial_code: '+77',
            emoji: '🇰🇿',
            code: 'KZ',
            polish_name: 'Kazachstan'
        },
        {
            name: 'Kenya',
            dial_code: '+254',
            emoji: '🇰🇪',
            code: 'KE',
            polish_name: 'Kenia'
        },
        {
            name: 'Kiribati',
            dial_code: '+686',
            emoji: '🇰🇮',
            code: 'KI',
            polish_name: 'Kiribati'
        },
        {
            name: 'Korea, Democratic People\'s Republic of Korea',
            dial_code: '+850',
            emoji: '🇰🇵',
            code: 'KP',
            polish_name: 'Korea Północna'
        },
        {
            name: 'Korea, Republic of South Korea',
            dial_code: '+82',
            emoji: '🇰🇷',
            code: 'KR',
            polish_name: 'Korea Południowa'
        },
        {
            name: 'Kuwait',
            dial_code: '+965',
            emoji: '🇰🇼',
            code: 'KW',
            polish_name: 'Kuwejt'
        },
        {
            name: 'Kyrgyzstan',
            dial_code: '+996',
            emoji: '🇰🇬',
            code: 'KG',
            polish_name: 'Kirgistan'
        },
        {
            name: 'Laos',
            dial_code: '+856',
            emoji: '🇱🇦',
            code: 'LA',
            polish_name: 'Laos'
        },
        {
            name: 'Latvia',
            dial_code: '+371',
            emoji: '🇱🇻',
            code: 'LV',
            polish_name: 'Łotwa'
        },
        {
            name: 'Lebanon',
            dial_code: '+961',
            emoji: '🇱🇧',
            code: 'LB',
            polish_name: 'Liban'
        },
        {
            name: 'Lesotho',
            dial_code: '+266',
            emoji: '🇱🇸',
            code: 'LS',
            polish_name: 'Lesotho'
        },
        {
            name: 'Liberia',
            dial_code: '+231',
            emoji: '🇱🇷',
            code: 'LR',
            polish_name: 'Liberia'
        },
        {
            name: 'Libyan Arab Jamahiriya',
            dial_code: '+218',
            emoji: '🇱🇾',
            code: 'LY',
            polish_name: 'Libia'
        },
        {
            name: 'Liechtenstein',
            dial_code: '+423',
            emoji: '🇱🇮',
            code: 'LI',
            polish_name: 'Liechtenstein'
        },
        {
            name: 'Lithuania',
            dial_code: '+370',
            emoji: '🇱🇹',
            code: 'LT',
            polish_name: 'Litwa'
        },
        {
            name: 'Luxembourg',
            dial_code: '+352',
            emoji: '🇱🇺',
            code: 'LU',
            polish_name: 'Luksemburg'
        },
        {
            name: 'Macao',
            dial_code: '+853',
            emoji: '🇲🇴',
            code: 'MO',
            polish_name: 'Makau'
        },
        {
            name: 'Macedonia',
            dial_code: '+389',
            emoji: '🇲🇰',
            code: 'MK',
            polish_name: 'Macedonia Północna'
        },
        {
            name: 'Madagascar',
            dial_code: '+261',
            emoji: '🇲🇬',
            code: 'MG',
            polish_name: 'Madagaskar'
        },
        {
            name: 'Malawi',
            dial_code: '+265',
            emoji: '🇲🇼',
            code: 'MW',
            polish_name: 'Malawi'
        },
        {
            name: 'Malaysia',
            dial_code: '+60',
            emoji: '🇲🇾',
            code: 'MY',
            polish_name: 'Malezja'
        },
        {
            name: 'Maldives',
            dial_code: '+960',
            emoji: '🇲🇻',
            code: 'MV',
            polish_name: 'Malediwy'
        },
        {
            name: 'Mali',
            dial_code: '+223',
            emoji: '🇲🇱',
            code: 'ML',
            polish_name: 'Mali'
        },
        {
            name: 'Malta',
            dial_code: '+356',
            emoji: '🇲🇹',
            code: 'MT',
            polish_name: 'Malta'
        },
        {
            name: 'Marshall Islands',
            dial_code: '+692',
            emoji: '🇲🇭',
            code: 'MH',
            polish_name: 'Wyspy Marshalla'
        },
        {
            name: 'Martinique',
            dial_code: '+596',
            emoji: '🇲🇶',
            code: 'MQ',
            polish_name: 'Martynika'
        },
        {
            name: 'Mauritania',
            dial_code: '+222',
            emoji: '🇲🇷',
            code: 'MR',
            polish_name: 'Mauretania'
        },
        {
            name: 'Mauritius',
            dial_code: '+230',
            emoji: '🇲🇺',
            code: 'MU',
            polish_name: 'Mauritius'
        },
        {
            name: 'Mayotte',
            dial_code: '+262',
            emoji: '🇾🇹',
            code: 'YT',
            polish_name: 'Majotta'
        },
        {
            name: 'Mexico',
            dial_code: '+52',
            emoji: '🇲🇽',
            code: 'MX',
            polish_name: 'Meksyk'
        },
        {
            name: 'Micronesia, Federated States of Micronesia',
            dial_code: '+691',
            emoji: '🇫🇲',
            code: 'FM',
            polish_name: 'Mikronezja'
        },
        {
            name: 'Moldova',
            dial_code: '+373',
            emoji: '🇲🇩',
            code: 'MD',
            polish_name: 'Mołdawia'
        },
        {
            name: 'Monaco',
            dial_code: '+377',
            emoji: '🇲🇨',
            code: 'MC',
            polish_name: 'Monako'
        },
        {
            name: 'Mongolia',
            dial_code: '+976',
            emoji: '🇲🇳',
            code: 'MN',
            polish_name: 'Mongolia'
        },
        {
            name: 'Montenegro',
            dial_code: '+382',
            emoji: '🇲🇪',
            code: 'ME',
            polish_name: 'Czarnogóra'
        },
        {
            name: 'Montserrat',
            dial_code: '+1 664',
            emoji: '🇲🇸',
            code: 'MS',
            polish_name: 'Montserrat'
        },
        {
            name: 'Morocco',
            dial_code: '+212',
            emoji: '🇲🇦',
            code: 'MA',
            polish_name: 'Maroko'
        },
        {
            name: 'Mozambique',
            dial_code: '+258',
            emoji: '🇲🇿',
            code: 'MZ',
            polish_name: 'Mozambik'
        },
        {
            name: 'Myanmar',
            dial_code: '+95',
            emoji: '🇲🇲',
            code: 'MM',
            polish_name: 'Myanmar'
        },
        {
            name: 'Namibia',
            emoji: '🇳🇦',
            dial_code: '+264',
            code: 'NA',
            polish_name: 'Namibia'
        },
        {
            name: 'Nauru',
            dial_code: '+674',
            emoji: '🇳🇷',
            code: 'NR',
            polish_name: 'Nauru'
        },
        {
            name: 'Nepal',
            dial_code: '+977',
            emoji: '🇳🇵',
            code: 'NP',
            polish_name: 'Nepal'
        },
        {
            name: 'Netherlands',
            dial_code: '+31',
            emoji: '🇳🇱',
            code: 'NL',
            polish_name: 'Holandia'
        },
        {
            name: 'Netherlands Antilles',
            dial_code: '+599',
            emoji: '🇧🇶',
            code: 'AN',
            polish_name: 'Antyle Holenderskie'
        },
        {
            name: 'New Caledonia',
            dial_code: '+687',
            emoji: '🇳🇨',
            code: 'NC',
            polish_name: 'Nowa Kaledonia'
        },
        {
            name: 'New Zealand',
            dial_code: '+64',
            emoji: '🇳🇿',
            code: 'NZ',
            polish_name: 'Nowa Zelandia'
        },
        {
            name: 'Nicaragua',
            dial_code: '+505',
            emoji: '🇳🇮',
            code: 'NI',
            polish_name: 'Nikaragua'
        },
        {
            name: 'Niger',
            dial_code: '+227',
            emoji: '🇳🇪',
            code: 'NE',
            polish_name: 'Niger'
        },
        {
            name: 'Nigeria',
            dial_code: '+234',
            emoji: '🇳🇬',
            code: 'NG',
            polish_name: 'Nigeria'
        },
        {
            name: 'Niue',
            dial_code: '+683',
            emoji: '🇳🇺',
            code: 'NU',
            polish_name: 'Niue'
        },
        {
            name: 'Norfolk Island',
            dial_code: '+672',
            emoji: '🇳🇫',
            code: 'NF',
            polish_name: 'Norfolk'
        },
        {
            name: 'Northern Mariana Islands',
            dial_code: '+1 670',
            emoji: '🇲🇵',
            code: 'MP',
            polish_name: 'Mariany Północne'
        },
        {
            name: 'Norway',
            dial_code: '+47',
            emoji: '🇳🇴',
            code: 'NO',
            polish_name: 'Norwegia'
        },
        {
            name: 'Oman',
            dial_code: '+968',
            emoji: '🇴🇲',
            code: 'OM',
            polish_name: 'Oman'
        },
        {
            name: 'Pakistan',
            dial_code: '+92',
            emoji: '🇵🇰',
            code: 'PK',
            polish_name: 'Pakistan'
        },
        {
            name: 'Palau',
            dial_code: '+680',
            emoji: '🇵🇼',
            code: 'PW',
            polish_name: 'Palau'
        },
        {
            name: 'Palestinian Territory, Occupied',
            dial_code: '+970',
            emoji: '🇵🇸',
            code: 'PS',
            polish_name: 'Terytorium Palestyńskie'
        },
        {
            name: 'Panama',
            dial_code: '+507',
            emoji: '🇵🇦',
            code: 'PA',
            polish_name: 'Panama'
        },
        {
            name: 'Papua New Guinea',
            dial_code: '+675',
            emoji: '🇵🇬',
            code: 'PG',
            polish_name: 'Papua-Nowa Gwinea'
        },
        {
            name: 'Paraguay',
            dial_code: '+595',
            emoji: '🇵🇾',
            code: 'PY',
            polish_name: 'Paragwaj'
        },
        {
            name: 'Peru',
            dial_code: '+51',
            emoji: '🇵🇪',
            code: 'PE',
            polish_name: 'Peru'
        },
        {
            name: 'Philippines',
            dial_code: '+63',
            emoji: '🇵🇭',
            code: 'PH',
            polish_name: 'Filipiny'
        },
        {
            name: 'Pitcairn',
            dial_code: '+872',
            emoji: '🇵🇳',
            code: 'PN',
            polish_name: 'Pitcairn'
        },
        {
            name: 'Poland',
            dial_code: '+48',
            emoji: '🇵🇱',
            code: 'PL',
            polish_name: 'Polska'
        },
        {
            name: 'Portugal',
            dial_code: '+351',
            emoji: '🇵🇹',
            code: 'PT',
            polish_name: 'Portugalia'
        },
        {
            name: 'Puerto Rico',
            dial_code: '+1 939',
            emoji: '🇵🇷',
            code: 'PR',
            polish_name: 'Puerto Rico'
        },
        {
            name: 'Qatar',
            dial_code: '+974',
            emoji: '🇶🇦',
            code: 'QA',
            polish_name: 'Katar'
        },
        {
            name: 'Romania',
            dial_code: '+40',
            emoji: '🇷🇴',
            code: 'RO',
            polish_name: 'Rumunia'
        },
        {
            name: 'Russia',
            dial_code: '+7',
            emoji: '🇷🇺',
            code: 'RU',
            polish_name: 'Rosja'
        },
        {
            name: 'Rwanda',
            dial_code: '+250',
            emoji: '🇷🇼',
            code: 'RW',
            polish_name: 'Rwanda'
        },
        {
            name: 'Reunion',
            dial_code: '+262',
            emoji: '🇷🇪',
            code: 'RE',
            polish_name: 'Reunion'
        },
        {
            name: 'Saint Barthelemy',
            dial_code: '+590',
            emoji: '🇧🇱',
            code: 'BL',
            polish_name: 'Saint Barthelemy'
        },
        {
            name: 'Saint Helena, Ascension and Tristan Da Cunha',
            dial_code: '+290',
            emoji: '🇸🇭',
            code: 'SH',
            polish_name: 'Wyspa Świętej Heleny'
        },
        {
            name: 'Saint Kitts and Nevis',
            dial_code: '+1 869',
            emoji: '🇰🇳',
            code: 'KN',
            polish_name: 'Saint Kitts i Nevis'
        },
        {
            name: 'Saint Lucia',
            dial_code: '+1 758',
            emoji: '🇱🇨',
            code: 'LC',
            polish_name: 'Saint Lucia'
        },
        {
            name: 'Saint Martin',
            dial_code: '+590',
            emoji: '🇲🇫',
            code: 'MF',
            polish_name: 'Saint Martin'
        },
        {
            name: 'Saint Pierre and Miquelon',
            dial_code: '+508',
            emoji: '🇵🇲',
            code: 'PM',
            polish_name: 'Saint Pierre i Miquelon'
        },
        {
            name: 'Saint Vincent and the Grenadines',
            dial_code: '+1 784',
            emoji: '🇻🇨',
            code: 'VC',
            polish_name: 'Saint Vincent i Grenadyny'
        },
        {
            name: 'Samoa',
            dial_code: '+685',
            emoji: '🇼🇸',
            code: 'WS',
            polish_name: 'Samoa'
        },
        {
            name: 'San Marino',
            dial_code: '+378',
            emoji: '🇸🇲',
            code: 'SM',
            polish_name: 'San Marino'
        },
        {
            name: 'Sao Tome and Principe',
            dial_code: '+239',
            emoji: '🇸🇹',
            code: 'ST',
            polish_name: 'Wyspy Świętego Tomasza i Książęca'
        },
        {
            name: 'Saudi Arabia',
            dial_code: '+966',
            emoji: '🇸🇦',
            code: 'SA',
            polish_name: 'Arabia Saudyjska'
        },
        {
            name: 'Senegal',
            dial_code: '+221',
            emoji: '🇸🇳',
            code: 'SN',
            polish_name: 'Senegal'
        },
        {
            name: 'Serbia',
            dial_code: '+381',
            emoji: '🇷🇸',
            code: 'RS',
            polish_name: 'Serbia'
        },
        {
            name: 'Seychelles',
            dial_code: '+248',
            emoji: '🇸🇨',
            code: 'SC',
            polish_name: 'Seszele'
        },
        {
            name: 'Sierra Leone',
            dial_code: '+232',
            emoji: '🇸🇱',
            code: 'SL',
            polish_name: 'Sierra Leone'
        },
        {
            name: 'Singapore',
            dial_code: '+65',
            emoji: '🇸🇬',
            code: 'SG',
            polish_name: 'Singapur'
        },
        {
            name: 'Slovakia',
            dial_code: '+421',
            emoji: '🇸🇰',
            code: 'SK',
            polish_name: 'Słowacja'
        },
        {
            name: 'Slovenia',
            dial_code: '+386',
            emoji: '🇸🇮',
            code: 'SI',
            polish_name: 'Słowenia'
        },
        {
            name: 'Solomon Islands',
            dial_code: '+677',
            emoji: '🇸🇧',
            code: 'SB',
            polish_name: 'Wyspy Salomona'
        },
        {
            name: 'Somalia',
            dial_code: '+252',
            emoji: '🇸🇴',
            code: 'SO',
            polish_name: 'Somalia'
        },
        {
            name: 'South Africa',
            dial_code: '+27',
            emoji: '🇿🇦',
            code: 'ZA',
            polish_name: 'Republika Południowej Afryki'
        },
        {
            name: 'South Sudan',
            dial_code: '+211',
            emoji: '🇸🇸',
            code: 'SS',
            polish_name: 'Sudan'
        },
        {
            name: 'South Georgia and the South Sandwich Islands',
            dial_code: '+500',
            emoji: '🇬🇸',
            code: 'GS',
            polish_name: 'Georgia Południowa i Sandwich Południowy'
        },
        {
            name: 'Spain',
            dial_code: '+34',
            emoji: '🇪🇸',
            code: 'ES',
            polish_name: 'Hiszpania'
        },
        {
            name: 'Sri Lanka',
            dial_code: '+94',
            emoji: '🇱🇰',
            code: 'LK',
            polish_name: 'Sri Lanka'
        },
        {
            name: 'Sudan',
            dial_code: '+249',
            emoji: '🇸🇩',
            code: 'SD',
            polish_name: 'Sudan'
        },
        {
            name: 'Suriname',
            dial_code: '+597',
            emoji: '🇸🇷',
            code: 'SR',
            polish_name: 'Surinam'
        },
        {
            name: 'Svalbard and Jan Mayen',
            dial_code: '+47',
            emoji: '🇸🇯',
            code: 'SJ',
            polish_name: 'Svalbard i Jan Mayen'
        },
        {
            name: 'Swaziland',
            dial_code: '+268',
            emoji: '🇸🇿',
            code: 'SZ',
            polish_name: 'Suazi'
        },
        {
            name: 'Sweden',
            dial_code: '+46',
            emoji: '🇸🇪',
            code: 'SE',
            polish_name: 'Szwecja'
        },
        {
            name: 'Switzerland',
            dial_code: '+41',
            emoji: '🇨🇭',
            code: 'CH',
            polish_name: 'Szwajcaria'
        },
        {
            name: 'Syrian Arab Republic',
            dial_code: '+963',
            emoji: '🇸🇾',
            code: 'SY',
            polish_name: 'Syria'
        },
        {
            name: 'Taiwan',
            dial_code: '+886',
            emoji: '🇹🇼',
            code: 'TW',
            polish_name: 'Tajwan'
        },
        {
            name: 'Tajikistan',
            dial_code: '+992',
            emoji: '🇹🇯',
            code: 'TJ',
            polish_name: 'Tadżykistan'
        },
        {
            name: 'Tanzania, United Republic of Tanzania',
            dial_code: '+255',
            emoji: '🇹🇿',
            code: 'TZ',
            polish_name: 'Tanzania'
        },
        {
            name: 'Thailand',
            dial_code: '+66',
            emoji: '🇹🇭',
            code: 'TH',
            polish_name: 'Tajlandia'
        },
        {
            name: 'Timor-Leste',
            dial_code: '+670',
            emoji: '🇹🇱',
            code: 'TL',
            polish_name: 'Timor Wschodni'
        },
        {
            name: 'Togo',
            dial_code: '+228',
            emoji: '🇹🇬',
            code: 'TG',
            polish_name: 'Togo'
        },
        {
            name: 'Tokelau',
            dial_code: '+690',
            emoji: '🇹🇰',
            code: 'TK',
            polish_name: 'Tokelau'
        },
        {
            name: 'Tonga',
            dial_code: '+676',
            emoji: '🇹🇴',
            code: 'TO',
            polish_name: 'Tonga'
        },
        {
            name: 'Trinidad and Tobago',
            dial_code: '+1 868',
            emoji: '🇹🇹',
            code: 'TT',
            polish_name: 'Trynidad i Tobago'
        },
        {
            name: 'Tunisia',
            dial_code: '+216',
            emoji: '🇹🇳',
            code: 'TN',
            polish_name: 'Tunezja'
        },
        {
            name: 'Turkey',
            dial_code: '+90',
            emoji: '🇹🇷',
            code: 'TR',
            polish_name: 'Turcja'
        },
        {
            name: 'Turkmenistan',
            dial_code: '+993',
            emoji: '🇹🇲',
            code: 'TM',
            polish_name: 'Turkmenistan'
        },
        {
            name: 'Turks and Caicos Islands',
            dial_code: '+1 649',
            emoji: '🇹🇨',
            code: 'TC',
            polish_name: 'Turks i Caicos'
        },
        {
            name: 'Tuvalu',
            dial_code: '+688',
            emoji: '🇹🇻',
            code: 'TV',
            polish_name: 'Tuvalu'
        },
        {
            name: 'Uganda',
            dial_code: '+256',
            emoji: '🇺🇬',
            code: 'UG',
            polish_name: 'Uganda'
        },
        {
            name: 'Ukraine',
            dial_code: '+380',
            emoji: '🇺🇦',
            code: 'UA',
            polish_name: 'Ukraina'
        },
        {
            name: 'United Arab Emirates',
            dial_code: '+971',
            emoji: '🇦🇪',
            code: 'AE',
            polish_name: 'Zjednoczone Emiraty Arabskie'
        },
        {
            name: 'United Kingdom',
            dial_code: '+44',
            emoji: '🇬🇧',
            code: 'GB',
            polish_name: 'Wielka Brytania'
        },
        {
            name: 'United States',
            dial_code: '+1',
            emoji: '🇺🇸',
            code: 'US',
            polish_name: 'Stany Zjednoczone'
        },
        {
            name: 'Uruguay',
            dial_code: '+598',
            emoji: '🇺🇾',
            code: 'UY',
            polish_name: 'Urugwaj'
        },
        {
            name: 'Uzbekistan',
            dial_code: '+998',
            emoji: '🇺🇿',
            code: 'UZ',
            polish_name: 'Uzbekistan'
        },
        {
            name: 'Vanuatu',
            dial_code: '+678',
            emoji: '🇻🇺',
            code: 'VU',
            polish_name: 'Vanuatu'
        },
        {
            name: 'Venezuela, Bolivarian Republic of Venezuela',
            dial_code: '+58',
            emoji: '🇻🇪',
            code: 'VE',
            polish_name: 'Wenezuela'
        },
        {
            name: 'Vietnam',
            dial_code: '+84',
            emoji: '🇻🇳',
            code: 'VN',
            polish_name: 'Wietnam'
        },
        {
            name: 'Virgin Islands, British',
            dial_code: '+1 284',
            emoji: '🇻🇬',
            code: 'VG',
            polish_name: 'Brytyjskie Wyspy Dziewicze'
        },
        {
            name: 'Virgin Islands, U.S.',
            dial_code: '+1 340',
            emoji: '🇻🇮',
            code: 'VI',
            polish_name: 'Wyspy Dziewicze Stanów Zjednoczonych'
        },
        {
            name: 'Wallis and Futuna',
            dial_code: '+681',
            emoji: '🇼🇫',
            code: 'WF',
            polish_name: 'Wallis i Futuna'
        },
        {
            name: 'Yemen',
            dial_code: '+967',
            emoji: '🇾🇪',
            code: 'YE',
            polish_name: 'Jemen'
        },
        {
            name: 'Zambia',
            dial_code: '+260',
            emoji: '🇿🇲',
            code: 'ZM',
            polish_name: 'Zambia'
        },
        {
            name: 'Zimbabwe',
            dial_code: '+263',
            emoji: '🇿🇼',
            code: 'ZW',
            polish_name: 'Zimbabwe'
        }
    ];
