
import { Component } from 'vue-property-decorator';
import { invoicesListHeaders } from '@/modules/invoices/shared/config';
import ListComponent from '@/shared/components/layout/list/list-component';
import InvoiceDetails from '@/modules/invoices/components/details.vue';
import InvoiceForm from '@/modules/invoices/components/form.vue';
import { InvoiceDataRequest } from '@/modules/invoices/shared/requests/invoice-data-request';
import { invoicePaymentMethods, invoiceStatuses } from '@/shared/config/invoices';
import { callSuper, datesCompare } from '@/shared/helpers';
import { Invoice } from '@/modules/invoices/shared/models/invoice';
import { invoicesListFilters } from '@/modules/invoices/shared/config/filters';
import { mdiDownload } from '@mdi/js';
import ItemsTable from '@/shared/components/layout/list.vue';
import { invoicesModule } from '@/modules/invoices/shared/state/module';
import { ListFilter } from '@/shared/types';
import { ModuleProps } from '@/shared/state/template/module-props';
import { departmentsModule } from '@/modules/departments/shared/state/module';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        InvoiceDetails,
        ItemsTable,
        InvoiceForm,
        TooltipBtn,
    },
})
export default class InvoicesList extends ListComponent<Invoice, InvoiceDataRequest> {
    public icons: object = {
        mdiDownload,
    };
    public departmentsTypes = departmentsModule.actionsTypes;
    public headers: object[] = invoicesListHeaders;
    public store = this.$store.state.invoicesState;
    public actionsTypes = invoicesModule.actionsTypes;
    public mutationTypes = invoicesModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public props: ModuleProps = invoicesModule.moduleProps;
    public filterConfig: ListFilter[] = invoicesListFilters;

    public created() {
        this.fetchData();
    }

    get downloading() {
        return this.store.downloadingItem;
    }

    public download(type: string) {
        this.fetchParams.location = type;
        this.$store.dispatch(this.actionsTypes.DOWNLOAD_LIST, this.fetchParams).then((response) => {
            if (response && response instanceof ArrayBuffer) {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Lista Faktur.' + type);
                document.body.appendChild(link);
                link.click();
            }
        });
    }

    public invoiceColor(invoice: Invoice) {
        if (invoice.paid) {
            return 'success';
        }

        return datesCompare(String(invoice.payment_deadline_date)) ? 'default' : 'warning';
    }

    public itemSaved(eventData?: any) {
        if (!this.edit && eventData && !eventData.next) {
            this.formDialog = false;
            this.current = eventData.data;
            return this.goToItem('invoice-details', { id: String(eventData.data.id) });
        }

        callSuper(this, 'itemSaved', );
    }

    get companyDepartments() {
        return this.$store.state.departmentsState.dataList;
    }

    public statusType(statusType: string) {
        return invoiceStatuses.find((obj) => obj.id === statusType);
    }

    public paymentMethod(name: string) {
        return invoicePaymentMethods.find((obj) => obj.id === name);
    }
}
