
import { phoneNumberPrefixes } from '@/shared/config/phone-numbers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, VModel } from 'vue-property-decorator';
import PhoneInput from './phone-input.vue';
import TooltipBtn from './tooltip-btn.vue';

@Component({
    components: {
        PhoneInput,
        TooltipBtn,
    },
})
export default class PhoneInputMultiple extends Vue {
    @Prop({ type: String, default: 'Numery telefonu' }) public label!: string;
    @VModel() public  phoneNumbers!: string[];
    public prefixes = phoneNumberPrefixes;

    public newNumber = '';
    public err = false;

    public addNumber() {
        if (this.err) { return; }
        this.phoneNumbers = [this.newNumber, ...(this.phoneNumbers ? this.phoneNumbers : [])];
        this.newNumber = '';
    }

    public removeNumber(phone: string) {
        this.phoneNumbers = this.phoneNumbers.filter((el) => el !== phone);
    }

    public getFlag(phone: string) {
        const prefix = phone.split(' ')[0];

        const countryData = this.prefixes.find((el) => el.dial_code === prefix);
        if (!countryData) {
            this.removeNumber(phone);
            return;
        }
        return countryData!.emoji;
    }
}
