
import { Component } from 'vue-property-decorator';
import { InvoiceDataRequest } from '@/modules/invoices/shared/requests/invoice-data-request';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { logger } from '@/shared/services';
import { departmentsModule } from '@/modules/departments/shared/state/module';
import { contractorsModule } from '@/modules/contractors/shared/state/module';
import { invoicePaymentDeadlines, invoicePaymentMethods, invoiceTypes } from '@/shared/config/invoices';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import { callSuper, parseDate } from '@/shared/helpers';
import { invoicesModule } from '@/modules/invoices/shared/state/module';
import { Contractor } from '@/modules/contractors/shared/models/contractor';
import { Department } from '@/modules/departments/shared/models/department';
import ContractorForm from '@/modules/contractors/components/form.vue';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';

@Component({
    components: {
        FormActions,
        DatePicker,
        AutoCompleteWithAddNew,
    },
    props: {
        itemData: Object,
        edit: {
            type: Boolean,
            default: false,
        },
        approved: Boolean,
        show: Boolean,
        next: {
            type: Boolean,
            default: true,
        },
    },
})
export default class InvoiceForm extends FormComponent<InvoiceDataRequest> {
    public contractorsTypes = contractorsModule.actionsTypes;
    public departmentsTypes = departmentsModule.actionsTypes;
    public paymentDeadlines = invoicePaymentDeadlines;
    public store = this.$store.state.invoicesState;
    public actionsTypes = invoicesModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new InvoiceDataRequest();
    public paymentMethods = invoicePaymentMethods;
    public paymentDeadlineDate: string = '';
    public recipient: Contractor | null = null;
    public invoiceTypes = invoiceTypes;

    public contractorsModule = contractorsModule;
    public contractorsState = this.$store.state.contractorsState;
    public ContractorForm = ContractorForm;

    get formTitle() {
        return this.edit ? 'Edytuj fakturę' : 'Dodaj fakturę';
    }

    get contractors() {
        return this.$store.state.contractorsState.dataList;
    }

    get loadingContractors() {
        return this.$store.state.contractorsState.loadingList;
    }

    get companyDepartments() {
        return this.$store.state.departmentsState.dataList;
    }

    get loadingDepartments() {
        return this.$store.state.departmentsState.loading || this.$store.state.departmentsState.loadingItem;
    }

    get user() {
        return this.$store.state.authState.user.name;
    }

    public issueDateChanged(value: string) {
        this.itemData.issue_date = value;
        this.paymentDeadlineDateChange();
    }

    public paymentDeadlineDateChange() {
        if (!!this.itemData.issue_date && this.itemData.payment_deadline >= 0) {
            const date = new Date(this.itemData.issue_date);
            if (this.itemData.payment_deadline > 0) {
                date.setDate(date.getDate() + this.itemData.payment_deadline);
            } else {
                date.setDate(date.getDate());
            }
            this.paymentDeadlineDate = parseDate(date) || '';
        } else {
            this.paymentDeadlineDate = '';
        }
    }

    public companyName(e: Contractor) {
        return e.name;
    }

    public changeRecipient(item: Contractor) {
        this.itemData.recipient_id = item.id;
        this.itemData.recipient_name = item.name;
        this.itemData.recipient_type_name = 'contractor';
        this.itemData.recipient_private = Number(!item.is_company);
    }

    public initForm() {
        this.form = this.$refs.createInvoiceForm;
        this.autocompleteInputs();
    }

    public autocompleteInputs() {
        if (!this.edit) {
            this.itemData.type = 'vat';
            this.itemData.recipient_type_name = 'contractor';

            this.$set(this.itemData, 'issue_date', this.parseDate(new Date()));
            this.$set(this.itemData, 'sale_date', this.parseDate(new Date()));
            this.$set(this.itemData, 'payment_deadline', 0);

            this.itemData.payment_method = this.paymentMethods.find((item) => item.main)!.id;
            this.itemData.seller_id = 1;

            // TODO: Removed because of departments temporary removal
            // this.$store.dispatch(this.departmentsTypes.FETCH_DATA, {simple: true})
            //     .catch((error) => {
            //         logger.error(error);
            //     })
            //     .finally(() => {
            //         if (this.companyDepartments) {
            //             const department = this.companyDepartments.find((item: Department) => item.main);
            //
            //             if (department) {
            //                 this.itemData = Object.assign(this.itemData,
            //                     {
            //                         seller_id: department.id,
            //                     },
            //                 );
            //                 this.getDepartment(department.id);
            //             }
            //         }
            //     });
        }
    }

    public initFocus() {
        if (!this.edit) {
            this.autocompleteInputs();
        }
    }

    public created() {
        this.$store.dispatch(this.contractorsTypes.FETCH_DATA, { simple: true }).catch((error) => {
            logger.error(error);
        });
        this.$store.dispatch(this.departmentsTypes.FETCH_DATA, { simple: true }).catch((error) => {
            logger.error(error);
        });
    }

    public async getDepartment(e: number) {
        this.clearError('department_id');
        await this.$store.dispatch(this.departmentsTypes.SHOW_ITEM, e).then(({ data }) => {
            if (data) {
                this.itemData.issue_place = data.city;
                this.$forceUpdate();
            }
        });
    }
}
