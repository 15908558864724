
import {Component} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

// state module
import {contractorsSpecializationsModule} from '../../../shared/state/settings/contractor-specializations/module';

// dataRequest
import {ContractorSpecializationDataRequest} from '../../../shared/requests/settings/contractors-specialization-data-request';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {FormActions},
})
export default class ContractorsSpecializationsForm extends FormComponent<ContractorSpecializationDataRequest> {
    public actionsTypes = contractorsSpecializationsModule.actionsTypes;
    public store = this.$store.state.contractorsState.contractorsTypesModuleState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public mutationTypes = contractorsSpecializationsModule.mutationsTypes;
    public requestData = new ContractorSpecializationDataRequest();

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj specjalizację kontrahenta' : 'Dodaj specjalizację kontrahenta';
    }

    public initForm() {
        this.form = this.$refs.createDepartmentForm;
        callSuper(this, 'mounted');
    }
}
