
import { Component } from 'vue-property-decorator';
import ErrorBoundary from '@/shared/components/error-boundary/error-boundary.vue';
import { mdiCloseCircle } from '@mdi/js';
import ListComponent from '@/shared/components/layout/list/list-component';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import { ListFilter } from '@/shared/types';
import { invoiceItemsListFilters } from '@/modules/invoices/shared/config/filters';
import { invoiceItemsListHeaders } from '@/modules/invoices/shared/config';
import { InvoiceItem } from '@/modules/invoices/shared/models/invoice-item';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import { invoiceItemsModule } from '@/modules/invoices/shared/state/submodules';
import { ModuleProps } from '@/shared/state/template/module-props';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ToolbarProps } from '@/shared/models/toolbar-props';
import { InvoiceItemDataRequest } from '@/modules/invoices/shared/requests/invoice-item-data-request';
import { FormRules } from '@/shared/validation/form-rules';
import { actionsTypes as settingsActionsTypes } from '@/modules/settings/shared/state';
import ItemsSearch from '@/shared/components/layout/form/item-search.vue';
import { logger } from '@/shared/services';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    props: {
        invoiceId: String,
        editable: Boolean,
    },
    components: {
        ErrorBoundary,
        ActionConfirmDialog,
        ListToolbar,
        ItemsTable,
        ItemsSearch,
        TooltipBtn,
    },
})
export default class InvoiceItemsList extends ListComponent<InvoiceItem, InvoiceItemDataRequest> {
    public invoiceId!: string;
    public props: ModuleProps = invoiceItemsModule.moduleProps;
    public actionsTypes = invoiceItemsModule.actionsTypes;
    public mutationTypes = invoiceItemsModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public store = this.$store.state.invoicesState.itemsState;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public itemsList: InvoiceItem[] = [];
    public editable!: boolean;
    public form: any;
    public formRules: FormRules = new FormRules();
    public valid: boolean = true;
    public toolbarProps: ToolbarProps = {
        addButton: false,
    } as ToolbarProps;
    public icons: object = {
        mdiCloseCircle,
    };
    public filterData: ListFilter[] = invoiceItemsListFilters;
    public destroyObject = true;
    public newItemSubmited = true;

    get items() {
        return this.store.data;
    }
    set items(val: any) {
        this.store.data = val;
    }

    get loadingItem() {
        return this.store.loadingItem;
    }

    get units() {
        return this.$store.state.settingsState.units;
    }

    get formErrorMessages() {
        return this.store.errorMessages;
    }

    get itemsErrors() {
        return this.$store.state.invoicesState.itemsErrors;
    }

    get taxRates() {
        return this.$store.state.settingsState.taxRates;
    }

    get loadingTaxRates() {
        return this.$store.state.settingsState.loadingTaxRates;
    }

    get currency() {
        return this.$store.state.invoicesState.current ? this.$store.state.invoicesState.current.currency : 'PLN';
    }

    // @ts-ignore-next-line
    get headers() {
        let headers = invoiceItemsListHeaders;
        headers = [
            ...invoiceItemsListHeaders,
            { text: 'Cena netto' + (this.currency ? ` (${this.currency})` : ''), value: 'price' },
            { text: 'Cena netto' + (this.currency ? ` (${this.currency})` : ''), value: 'total_price_tax_excl' },
            { text: 'Kwota VAT' + (this.currency ? ` (${this.currency})` : ''), value: 'tax_total' },
            { text: 'Kwota brutto' + (this.currency ? ` (${this.currency})` : ''), value: 'total_price_tax_incl' },
        ];
        if (this.editable) {
            return [
                ...headers,
                {
                    text: 'Akcje',
                    value: 'actions',
                    align: 'center',
                },
            ];
        }
        return headers;
    }

    get loadingUnits() {
        return this.$store.state.settingsState.loadingUnits;
    }

    public created() {
        this.fetchUnits();
        this.fetchTaxRates();
        this.id = Number(this.invoiceId);
        if (Object.keys(this.$store.state.invoicesState.current).length < 1) {
            this.fetchData(this.pinItemsList);
        } else {
            this.pinItemsList(this.items);
        }
    }

    public mounted() {
        this.form = this.$refs.invoiceItemsForm;
    }

    public pinItemsList(e: InvoiceItem[]) {
        this.itemsList = JSON.parse(JSON.stringify(e));
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`invoices.items.${action}`);
    }

    public clearError(name: string, index?: number) {
        this.$forceUpdate();
        if (this.itemsErrors[`item_${index}`]) {
            this.itemsErrors[`item_${index}`][name] = [];
        }
        if (this.formErrorMessages[name]) {
            delete this.formErrorMessages[name];
            if (this.formErrorMessages.length > 0) {
                return;
            }
            return (this.store.error = '');
        }
    }

    public searchAttach(item: InvoiceItem, searchItem: any) {
        if (searchItem) {
            item.name = searchItem.name;
            item.price = searchItem.sell_price >= 0 ? searchItem.sell_price : item.price;
            item.tax_rate = String(searchItem.tax_rate);
            item.unit = searchItem.unit;
        }
    }

    public currentRow(id: number): Element | null {
        return document.activeElement ? document.activeElement.closest(`.item-${id}`) : null;
    }

    public priceTaxExcl(item: InvoiceItem) {
        return item.quantity * item.price;
    }

    public taxTotal(item: InvoiceItem) {
        return item.quantity * (item.price * ((Number(item.tax_rate) || 0) / 100));
    }

    public deleteItem(item: InvoiceItem) {
        if (item.id > 0) {
            callSuper(this, 'deleteItem', item);
        } else {
            this.newItemSubmited = true;
            this.items.splice(item.index ? item.index - 1 : 0, 1);
        }
    }

    public addItem() {
        const item = {
            id: (this.items.length + 1) * -1,
            name: '',
            quantity: 1,
            invoice_id: this.id,
            unit: 'szt',
            tax_rate: '23',
            index: this.items.length + 1,
        } as InvoiceItem;
        this.newItemSubmited = false;
        this.$store.commit(this.mutationTypes.ADD_ITEM, item);
        this.$forceUpdate();
    }

    public selectChanged(item: InvoiceItem) {
        const timeout = setTimeout(() => {
            if (!this.currentRow(item.id)) {
                if (item.index && !this._.isEqual(this.items[item.index - 1], this.itemsList[item.index - 1])) {
                    this.updateItem(item);
                }
            }
            clearTimeout(timeout);
        }, 1000);
    }

    public async updateItem(item: InvoiceItem) {
        await this.form.validate();
        if (this.valid) {
            const actionType = item.id > 0 ? this.actionsTypes.UPDATE_ITEM : this.actionsTypes.STORE_ITEM;
            this.$store.dispatch(actionType, item).then((response) => {
                if (item.index && response && response.data && response.data.id) {
                    response.data.index = item.index;

                    this.itemsList[item.index - 1] = Object.assign({}, response.data);
                    this.items.splice(item.index - 1, Number(item.id < 0));
                    this.items[item.index - 1] = response.data;

                    this.$forceUpdate();

                    this.$emit(
                        'itemsChanged',
                        this.getTotal(this.items.map((el: InvoiceItem) => this.priceTaxExcl(el))),
                        this.getTotal(this.items.map((el: InvoiceItem) => this.taxTotal(el))),
                    );
                }
                if (actionType === this.actionsTypes.STORE_ITEM) {
                    this.newItemSubmited = true;
                }
            });
        }
    }

    public rowClasses(item: InvoiceItem): string {
        if (this.formErrorMessages.id === item.id) {
            return 'row-error';
        }

        return '';
    }

    private getTotal(arr: any[]) {
        return arr.reduce((prev, curr) => {
            return (prev += curr);
        }, 0);
    }

    private fetchUnits() {
        this.$store.dispatch(settingsActionsTypes.FETCH_UNITS, { simple: true }).catch((error) => {
            logger.error(error);
        });
    }

    private fetchTaxRates() {
        this.$store.dispatch(settingsActionsTypes.FETCH_TAX_RATES, { simple: true }).catch((error) => {
            logger.error(error);
        });
    }
}
